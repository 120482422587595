.wallpapers{

    @media screen and (max-width: 1024px) {
        height: 600px;
    }

    @media screen and (max-width: 800px) {
        height: 700px;
        padding: 5% 5% 0 5%;
    }

    .projects_text{

        @media screen and (max-width: 1200px) {
            width: 40%;
        }

        @media screen and (max-width: 800px) {
            width: 100%;
        }

        .divButton{
            display: flex;
            align-items: center;
            margin-top: 2rem;

            @media screen and (max-width: 800px) {
                width: 100%;
            }

            p{
                font-size: 1.2rem;
                width: 75%;

                @media screen and (max-width: 500px) {
                    font-size: 1rem;
                }
            }

            .button__download{
                width: 8rem;
                height: 2.5rem;
                margin-left: 1rem;

                @media screen and (max-width: 1440px) {
                    margin-left: 1rem;
                    width: 8rem;
                }

                @media screen and (max-width: 1024px) {
                    width: 10rem;
                }

                .linkwallpaperpage{
                    width: 100%;
                }
            }
        }
    }
    
    .wallpapers__img_block{
        height: 100%;
        width: 63%;
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: 1200px) {
            width: 55%;
        }

        .wallpapers__img_content{
            height: 70%;
            width: 100%;
            display: flex;
            position: relative;
            justify-content: center;
            overflow: hidden;

            @media screen and (max-width: 1024px) {
                height: 65%;
            }

            @media screen and (max-width: 800px) {
                height: 85%;
            }

            .wallpapers__img{
                position: absolute;
                bottom: -50%;
                width: 40%;

                @media screen and (max-width: 1500px) {
                    width: 60%;
                }
            }

            .w_limg{
                left: 0;
                top: 5%;

                @media screen and (max-width: 400px) {
                    top: 15%;
                }
            }

            .w_cimg{
                z-index: 1;
                top: -10%;
                width: 50%;

                @media screen and (max-width: 1024px) {
                    top: -5%
                }

                @media screen and (max-width: 500px) {
                    top: 0;
                }
            }

            .w_rimg{
                right: 0;
                top: 5%;

                @media screen and (max-width: 400px) {
                    top: 15%;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .wallpapers{
    
        .wallpapers__img_block{
            width: 85%;
        }
    }
}

@media screen and (max-width: 450px) {
    .wallpapers{
        height: 525px;

        .projects_text{
            width: 100%;
        }
    }
}