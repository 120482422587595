@import './components/Base/Header/styles.module.scss';
@import './components/Base/Footer/styles.module.scss';
@import './components/Pages/Home/Content/Preview/styles.module.scss';
@import './components/Pages/Home/Content/OurProject/styles.module.scss';
@import './components/Pages/Home/Content/Targets/styles.module.scss';
@import './components/Pages/Home/Content/Partners/styles.module.scss';
@import './components/Pages/Home/Content/Team/styles.module.scss';
@import './components/Pages/Home/Content/Modal/styles.module.scss';
@import './components/Pages/Home/Content/Wallpapers/styles.module.scss';
@import './components/Pages/Home/Content/Memorandum/styles.modules.scss';
@import './components/Pages/WallpaperPage/styles.module.scss';
@import './components/Pages/Home/Content/EduPlaces/styles.module.scss';
@import './components/ErrorGhost/styles.module.scss';


@font-face {
  font-family: Dnipro-Regular;
  src: url('../fonts/DniproCity-Regular.otf') format('opentype');
}

@font-face {
  font-family: DniproCity-Medium;
  src: url('../fonts/DniproCity-Medium.otf') format('opentype');
}

$dark: #252525;
$light: #eee;
$gray: #ececec;
$lightgray: #f4f4f4;

*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  min-height: 100vh;
  font-family: Dnipro-Regular;
  scroll-behavior: smooth;
  position: relative;
  overflow-x: hidden;
  margin: 0;
}

img {
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a,
a:visited,
a:active,
a:hover {
  color: inherit;
  text-decoration: none;
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: $lightgray;
}

body,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p,
a,
td,
li,
label,
input,
button {
  font-size: 1rem;
  list-style: none;
}

#root {
  margin: 0 auto;
}

@media screen and (max-width: 1440px) {
  #root {
    max-width: 1296px;
  }
}

@media screen and (max-width: 1024px) {
  #root {
    width: 92%;
  }
}

@media screen and (max-width: 820px) {
  #root {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  #root {
    width: 100%;
  }
}

//******************custom scrollBar******************
::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
  background-color: $light;

  /* ползунок скроллбара */
  &-thumb {
    border-radius: 0.2rem;
  }
}

/* Стрелки */
::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(120deg, $light 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(240deg, $light 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(0deg, $light 30%, rgba(0, 0, 0, 0) 31%);
}

::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(300deg, $light 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(60deg, $light 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(180deg, $light 30%, rgba(0, 0, 0, 0) 31%);
}

::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, $light 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(150deg, $light 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(270deg, $light 30%, rgba(0, 0, 0, 0) 31%);
}

::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, $light 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(330deg, $light 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(90deg, $light 30%, rgba(0, 0, 0, 0) 31%);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
  background-color: grey;

  &:hover {
    background-color: $dark;
  }
}