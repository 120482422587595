@import '../../../styles/utils/';

.page {
  @extend %center;
  height: 92vh;
  flex-direction: column;  
}

.error {
  @extend %mb-2;

  display: flex;
  justify-content: space-between;
  align-content: center;
}

.number {
  font-weight: 800;
  font-size: 15rem;
  line-height: 1;

  @include mobileAndTablet {
    font-size: 8rem;
  }
}

.text {
  @extend %mb-2;

  font-weight: 300;
  color: $blue;
}

// .button {
//   padding: 1.2rem 3rem;
//   background: #ffcebe;
//   border: 2px solid #fa855f;
//   border-radius: 50px;
// }

.button {
  width: 200px;
  height: 4rem;
  margin: 10px;
  box-sizing: border-box;
  display: inline-block;
  padding: 0.5em 0;
  background: none;
  border: none;
  border-top: 2px solid #efce7d;
  border-bottom: 2px solid #efce7d;
  border-image: linear-gradient(to right, #efce7d 0%, #7192e4 100%);
  border-image-slice: 1;
  position: relative;
  transition: background 0.3s;
  color: black;
  cursor: pointer;
  font-size: 1em;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 2em;
    height: calc(100% + 2px);
    border-radius: 3em 0 0 3em;
    border: 2px solid #efce7d;
    position: absolute;
    border-right: none;
    transition: background 0.3s;
    left: -2em;
    top: -1px;
  }
  &:after {
    border-radius: 0 3em 3em 0;
    border: 2px solid #7192e4;
    height: calc(100% + 2px);
    position: absolute;
    border-left: none;
    left: auto;
    right: -2em;
    top: -1px;
  }
  &:hover {
    background: rgba(36, 36, 36, 0.1);
    &:after,
    &:before {
      background: rgba(36, 36, 36, 0.1);
    }
  }
}