.footer {
  display: flex;
  justify-content: space-between;
  height: 135px;
  width: 100%;
  align-items: center;
  padding: 0 0.5rem;
}

.footer__eDnipro {
  display: flex;
  height: 100%;
  width: 45%;
  align-items: center;
  justify-content: space-around;
}

.footer__logo {
  max-width: 25%;
  margin-right: 5rem;
}

.footer__contact {
  display: flex;
  align-items: center;
  width: 52%;
  justify-content: space-around;
}

.footer__contacts {
  display: flex;
}

.footer_mob {
  display: flex;
  align-items: center;
  width: 80%;
}

.footer_mob p {
  margin-right: 1rem;
}

.footer__contacts {
  line-height: 1.5;
}

.footer_social {
  display: flex;
  align-items: center;
  width: 18%;
  justify-content: space-around;
  margin-right: 5rem;
}

.footer__link_social {
  margin-left: 1rem;
}

.social__icon {
  max-width: 100%;
}

@media screen and (max-width: 1440px) {
  .footer {
    height: 85px;
  }

  .footer__eDnipro {
    width: 30%;
  }

  .footer__logo {
    margin-right: 2rem;
    max-width: 30%;
  }

  .footer__contact {
    width: 66%;
  }

  .footer_social {
    margin-right: 3rem;
  }

  .social__icon {
    width: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    height: 155px;
    flex-direction: column-reverse;
    margin-top: 1rem;

    .footer__eDnipro {
      height: 50%;
      width: 54%;

      .footer__logo {
        margin-right: 2rem;
      }

      p {
        font-size: 1.3rem;
      }
    }

    .footer__contact {
      height: 50%;
      width: 100%;

      .footer_mob p {
        margin-right: 1rem;
        font-size: 1.1rem;
      }

      .footer_social {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .footer {
    position: relative;

    .footer__eDnipro {
      justify-content: space-between;
      padding: 0 5%;
      width: 100%;
    }

    .footer__logo {
      width: 20%;
      height: 100%;
      margin-right: 1.5rem;
    }

    .footer__contact {
      padding: 1rem 0.5rem;
      flex-direction: column;

      .footer_mob p {
        font-size: 1.3rem;
      }
    }

    .footer__contacts {
      display: flex;
      margin-top: 0.6rem;
      justify-content: space-around;
      width: 100%;
    }

    .footer_mob {
      width: 100%;
      flex-direction: column;
    }

    .footer_mob #last__mob {
      margin-right: 0;
    }

    .footer_social {
      margin: 1rem 0 0 0;
      position: absolute;
      width: 30%;
      top: 48%;
      left: 65%;
    }

    .social__icon {
      width: 90%;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer {
    height: 125px;
    margin-bottom: 1.2rem;

    .footer__eDnipro {
      justify-content: flex-start;
      height: 49%;
      padding: 0 1%;

      .footer__logo {
        margin-right: 1rem;
        width: 23%;
      }

      p {
        font-size: 0.8rem;
      }
    }

    .footer__contact {
      .footer_mob {
        p {
          margin: 0 0 0.5rem 0;
          font-size: 0.8rem;
        }
      }
    }

    .footer_social {
      width: 30%;
      top: 48%;
      left: 65%;
    }
  }

  .footer_mob {
    flex-direction: column;
  }
}

@media screen and (max-width: 375px) {
  .footer {

    .footer__contact {
      padding: 1rem 0;

      .footer_social {
        left: 68%;
        top: 51.5%;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .footer {
    height: 200px;
    margin: 1rem 0 0 0;

    .footer__eDnipro {
      height: 30%;
      padding-top: 1rem;
      justify-content: center;
    }

    .footer__contacts {
      flex-direction: column;
      align-items: center;
    }

    .footer__contact {

      .footer_mob {

        p {
          margin: 0 0 1rem 0;
        }
      }

      .footer_social {
        width: 100%;
        left: 0;
      }
    }

    .social__icon {
      width: 60%;
    }
  }
}