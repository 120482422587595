$bgr: rgb(244, 244, 244);
$white: #f4f4f4;
$blue: #7192e4;

.header {
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0 5%;
  top: 0;
  // left: 0;
  z-index: 10;
  background-color: $bgr;
  opacity: 0.9;

  .menu {
    width: 3rem;
    position: absolute;
    top: 2rem;
    right: 4rem;
    fill: black;
    cursor: pointer;
    display: none;
  }
}

//////////////////////////////////////////////

header {
  position: absolute;
  z-index: 10;

  .close {
    width: 1.5rem;
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .row {
    padding: 1rem 0;
    align-items: center;
    justify-content: space-between;

    .tabs {
      width: 70%;
      text-align: center;

      a {
        margin: 0 1rem;
        padding: 0.5rem 0;
        position: relative;
        font-weight: 700;
        font-size: 1.2rem;

        &::after {
          content: '';
          width: 0;
          height: 0.1rem;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: $blue;
          transition: 0.3s;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  /////////////////////////////////////////////////////////
  /* .header_container:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */

  .header__logo {
    /* align-items: center;
        justify-content: center;
        display: flex; */
    margin-right: 15%;
    width: 24%;
  }

  .header__logo img {
    max-width: 70%;
    display: block;

    @media screen and (max-width: 1100px) {
      max-width: 95%;
    }

    @media screen and (max-width: 500px) {
      max-width: 100%;
    }
  }

  .header__nav {
    width: 72%;
  }

  ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0;
  }

  ul li {
    margin: 0 8% 0 0;
  }

  .header__link {
    font-size: 1.4rem;
    transition: 0.7s ease;
  }

  .header__link:hover {
    color: $blue;
  }

  .header__burger {
    display: none;
    cursor: pointer;
  }

  .bar {
    display: block;
    width: 2.5rem;
    height: 0.4px;
    margin: 0.8rem auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #333;
  }
}

@media screen and (max-width: 1024px) {

  .header {
    padding: 0 2rem;

    .header__logo {
      margin-right: 3%;
    }

    .tabs {
      // height: 80%;
      // flex-direction: column;
      // justify-content: start;
      position: absolute;
      left: -200%;
      // display: none;
    }

    .menu {
      display: block;
      top: 1rem;
      right: 3rem;
    }
  }

  .header__list li {
    margin: 0 5% 0 0;
  }

  .header__link {
    font-size: 22px;
  }

  ////////////////////////////////

  .open {
    left: 0;
  }

  .close {
    position: absolute;
    left: -200%;
  }

  ul.open {
    width: 100%;
    height: 56vh;
    position: fixed;
    flex-direction: column;
    left: 0rem;
    top: 75px;
    z-index: 99999;
    text-align: center;
    bottom: 2rem;
    background-color: rgb(244, 244, 244);
  }

  ul.close {
    left: -100%;
    // display: none;
  }

  //////////////////////////////////
}

@media screen and (max-width: 820px) {
  .header {
    height: 75px;
    width: 90%;
  }

  header {

    .header__logo {
      width: 30%;
      margin-right: 2%;
    }
  }

  .header__nav {
    width: 30%;
  }

  .header__list {
    position: fixed;
    left: -100%;
    top: 95px;
    flex-direction: column;
    background-color: $white;
    width: 100%;
    height: 95vh;
    text-align: center;
    transition: 0.4s;
    opacity: 0.95;
  }

  .header__list li {
    margin: 0;
  }

  .nav_item {
    margin: 1rem 0;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header__list.active {
    left: 0;
  }

}

@media screen and (max-width: 500px) {
  .header {
    height: 60px !important;
    width: 100%;

    .menu {
      top: 1rem;
      right: 2rem;
      width: 2rem;
    }
  }

  .header__list {
    top: 49px;
  }

  .bar {
    margin: 0.6rem auto;
    width: 1.5rem;
  }

  ul.open {
    top: 60px;
    padding-left: 0;
  }
}

@media screen and (max-width: 375px) {
  header {

    .header__logo {
      width: 35%;
    }

    // ul.open {
    //   height: 90vh;
    // }
  }
}

@media screen and (max-width: 320px) {
  header {

    .header__logo {
      width: 40%;
    }
  }
}