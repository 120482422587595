.wallpapers__page{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .wallpapers__prev_content{
        background-image: url(../../../assets/images/wallpaperPage/prevWall.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 750px;
        border-radius: 50px;
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 3rem;

        @media screen and (max-width: 768px) {
            width: 100%;
            height: 400px;
            padding-left: 0;
            align-items: center;
            text-align: center;
        }

        @media screen and (max-width:575px) {
            border-radius: 0;
        }

        .wallpapers__prev_text{

            .wallpapers__text_title{
                color: #fff;
                font-size: 1.5rem;

                @media screen and (max-width: 768px) {
                    background-color: #09080850;
                }
            }
        }

        .divButton{
            margin-top: 3rem;
            

            @media screen and (max-width: 768px) {
                width: 100% !important;
            }
        }
    }

    .project__wallpapers_content{

        .wallpaper__img_container{
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;

            .wallpaper__content{
                width: 19%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media screen and (max-width: 1024px) {
                    width: 24%;
                }

                @media screen and (max-width: 768px) {
                    width: 31%;
                }

                @media screen and (max-width: 575px) {
                    width: 47%;
                }
                
                .wallpaper__link{
                    width: 100%;
                    // height: 600px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .wallpaper__img{
                        width: 100%;
                    } 
                }

                .wallButton{
                    width: 90% !important;
                    margin: 1rem 0 3rem 0;

                    @media screen and (max-width: 1440px) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 575px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}