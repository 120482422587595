@import '../../styles/utils/';

.illustration {
  position: relative;

  width: 12.2rem;
  margin: 0 2.1rem;

  @include mobileAndTablet {
    width: 8rem;
    margin: 0 1.8rem;
  }

  .circle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 12.2rem;
    height: 11.4rem;
    border-radius: 50%;
    background-color: $blue;

    @include mobileAndTablet {
      width: 8rem;
      height: 7rem;
    }
  }

  .clip {
    position: absolute;
    bottom: 0.3rem;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    width: 12.5rem;
    height: 13rem;
    border-radius: 0 0 50% 50%;

    @include mobileAndTablet {
      width: 8.5rem;
      height: 9rem;
    }

    .paper {
      position: absolute;
      bottom: -0.3rem;
      left: 50%;
      transform: translateX(-50%);
      width: 9.2rem;
      height: 12.4rem;
      border: 0.3rem solid $blue;
      background-color: $mainColor;
      border-radius: 0.8rem;
  
      @include mobileAndTablet {
        width: 7rem;
        height: 8.5rem;
      }
      
      &::before {
        content: "";
        position: absolute;
        top: -0.7rem;
        right: -0.7rem;
        width: 1.4rem;
        height: 1rem;
        background-color: $mainColor;
        border-bottom: 0.3rem solid $blue;
        transform: rotate(45deg);
      }

      .face {
        position: relative;
        margin-top: 2.3rem;

        .eyes {
          position: absolute;
          top: 0;
          left: 2.4rem;
          width: 4.6rem;
          height: 0.8rem;
      
          @include mobileAndTablet {
            left: 1.9rem;
            width: 3.6rem;
            height: 0.6rem;
          }

          .eye {
            position: absolute;
            bottom: 0;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            background-color: $blue;
            animation-name: eye;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        
            @include mobileAndTablet {
              width: 0.6rem;
              height: 0.6rem;
            }
            
            &_left {
              left: 0;
            }
        
            &_right {
              right: 0;
            }
          }
        
          @keyframes eye {
            0% {
              height: 0.8rem;
        
              @include mobileAndTablet {
                height: 0.6rem;
              }
            }
            50% {
              height: 0.8rem;
        
              @include mobileAndTablet {
                height: 0.6rem;
              }
            }
            52% {
              height: 0.1rem;
            }
            54% {
              height: 0.8rem;
        
              @include mobileAndTablet {
                height: 0.6rem;
              }
            }
            100% {
              height: 0.8rem;
        
              @include mobileAndTablet {
                height: 0.6rem;
              }
            }
          }
        }

        .rosyCheeks {
          position: absolute;
          top: 1.6rem;
          width: 1rem;
          height: 0.2rem;
          border-radius: 50%;
          background-color: $pink;
      
          @include mobileAndTablet {
            width: 0.7rem;
          }
          
          &_left {
            left: 1.4rem;
          }
      
          &_right {
            right: 1.4rem;
          }
        }

        .mouth {
          position: absolute;
          top: 3.1rem;
          left: 50%;
          width: 1.6rem;
          height: 0.2rem;
          border-radius: 0.1rem;
          transform: translateX(-50%);
          background-color: $blue;
        }
      }
    }
  }
}
