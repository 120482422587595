.our_team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2eff3;
  border-radius: 100px;
  width: 100%;
  height: 826px;
  margin: 7% 0 5% 0;
  padding: 0 0 0 5%;

  .swiper {
    height: 100%;
  }
}

.our_team h3 {
  font-size: 6rem;
  font-weight: 600;
  width: 25%;
}

.staff__team {
  width: 70%;
  padding: 3% 3% 0 3%;
}

.staff__person {
  display: flex;
  align-items: flex-end;
  padding: 1.6rem !important;
}

.staff {
  width: calc(100% / 5);
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.staff__text {
  margin-top: 10%;
}

.staff__photo {
  max-width: 100%;
}

.staff h4 {
  font-size: 2rem;
}

.staff p {
  font-size: 1.5rem;
}

.staff h4,
.staff p {
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .our_team {
    height: 600px;
    border-radius: 50px;

    h3 {
      font-size: 5rem;
      width: 28%;
    }
  }

  .staff h4 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1024px) {
  .our_team {
    height: 420px;

    h3 {
      font-size: 4rem;
    }
  }

  .staff h4 {
    font-size: 1.3rem;
  }

  .staff p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 820px) {
  .our_team {
    border-radius: 100px;

    h3 {
      width: 100%;
      font-size: 2.4rem;
      text-align: center;
      padding-top: 1rem;
    }

    .swiper {
      width: 77%;
    }
  }
}

@media screen and (max-width: 500px) {
  .our_team{
    border-radius: 0;

    .swiper{
      width: 85%;
      margin: 0 5%;
    }
  }
}

@media screen and (max-width: 428px) {
  .our_team {
    border-radius: 0;
    padding: 0;
    height: 365px;

    .swiper {
      width: 90%;
      margin: 0 5%;
    }
  }

  .staff {
    width: calc(100% / 2);
  }

  .staff__text {
    margin-bottom: 0.7rem;
  }

  .staff p {
    font-size: 0.9rem;
  }

  .staff__photo {
    width: 95%;
  }

  .staff h4 {
    font-size: 1.1rem;
  }
}
