.content {
  max-width: 1800px;
  margin: 0 auto;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

@media screen and (max-width: 1440px) {
  .content {
    padding: 0;
  }
}

@media screen and (max-width: 768px){
  .content{
    margin-top: 85px;
  }
}

@media screen and (max-width: 500px) {
  .content {
    margin-top: 50px;
  }
}