@use 'sass:map';
@import './vars';
@import './mixins';

%mainText {
  font-size: map.get($mainText, "mobile");
  line-height: 1;
  color: $textColor;

  @include tablet {
    font-size: map.get($mainText, "tablet");
  }

  @include small {
    font-size: map.get($mainText, "small");
  }

  @include wide {
    font-size: map.get($mainText, "wide");
  }

  @include large {
    font-size: map.get($mainText, "large");
  }
}

%smallText {
  font-size: map.get($smallText, "mobile");
  line-height: 1;
  color: $textColor;

  @include tablet {
    font-size: map.get($smallText, "tablet");
  }

  @include small {
    font-size: map.get($smallText, "small");
  }

  @include wide {
    font-size: map.get($smallText, "wide");
  }

  @include large {
    font-size: map.get($smallText, "large");
  }
}

%buttonText {
  line-height: 1;
  color: $textColor;
  font-size: 14px;

  @include small {
    font-size: 16px;
  }

  @include large {
    font-size: 18px;
  }
}

%center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%mb-1 {
  margin-bottom: 10px;

  @include tablet {
    margin-bottom: 20px;
  }

  @include wide {
  margin-bottom: 25px;
  }

  @include large {
    margin-bottom: 30px;
  }
}

%mb-2 {
  margin-bottom: 25px;

  @include small {
    margin-bottom: 40px;
  }

  @include wide {
  margin-bottom: 55px;
  }

  @include large {
    margin-bottom: 70px;
  }
}

%mb-3 {
  margin-bottom: 50px;

  @include small {
    margin-bottom: 70px;
  }

  @include wide {
  margin-bottom: 90px;
  }

  @include large {
    margin-bottom: 150px;
  }
}

// margin top

%mt-1 {
  margin-top: 10px;

  @include small {
    margin-top: 20px;
  }

  @include wide {
  margin-top: 25px;
  }

  @include large {
    margin-top: 30px;
  }
}

%mt-2 {
  margin-top: 25px;

  @include small {
    margin-top: 40px;
  }

  @include wide {
  margin-top: 55px;
  }

  @include large {
    margin-top: 70px;
  }
}

%mt-3 {
  margin-top: 50px;

  @include small {
    margin-top: 70px;
  }

  @include wide {
  margin-top: 90px;
  }

  @include large {
    margin-top: 150px;
  }
}

%padding-horizontal {
  padding: 0 15px;

  @include tablet {
    padding: 0 28px;
  }

  @include small {
    padding: 0 36px;
  }

  @include wide {
    padding: 0 48px;
  }

  @include large {
    padding: 0 56px;
  }
}

%min-height-1 {
  min-height: 400px;

  @include tablet {
    min-height: 500px;
  }

  @include small {
    min-height: 600px;
  }

  @include wide {
    min-height: 850px;
  }
}

%primaryButton {
  width: 125px;
  height: 50px;
  border-radius: 44px;

  @include small {
    width: 150px;
  }

  @include wide {
    width: 200px;
    height: 60px;
  }
}

%primaryButton-big {
  width: 150px;
  height: 50px;
  border-radius: 44px;

  @include small {
    width: 210px;
    height: 70px;
  }

  @include wide {
    width: 300px;
    height: 100px;
  }
}

