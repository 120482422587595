.partners {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 3rem;
}

h3 {
  font-size: 1.7rem;
}

.flip-cards {
  overflow: hidden;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
}

.our__partners {
  background-color: #ececec;
  border-radius: 50px;
  width: calc(1700px / 5);
  height: 313px;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .partners__grayscale {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    transition: all 0.5s ease 0s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
  }

  a:hover img {
    // content: '';
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    filter: grayscale(0);
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
  }
}

.partners__pointer{
  cursor: pointer;
  margin-top: 1rem;
}

.long__partners {
  width: calc(1700px / 5 * 2 + 1rem);
}

.partners__bottom{

  @media screen and (max-width: 768px) {
    width: 592px;
  }

  @media screen and (max-width: 450px) {
    width: 382px;
  }

  @media screen and (max-width: 375px) {
    width: 352px;
  }

  @media screen and (max-width: 320px) {
    width: 304px;
  }
}

.part_longest{
  width: 49%;

  @media screen and (max-width: 1500px) {
    min-width: 45%;
  }

  @media screen and (max-width: 1100px) {
    min-width: 60%;
  }

  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
}

.partners__monroznov {
  display: flex;
}

.partners__high {
  height: calc(313px * 2 + 0.5rem);
}

.partners__logo {
  max-width: 100%;
}

.partners__topline {
  display: flex;
}

.partners_gradient {
  position: relative;
  border-image-source: initial;
  border: solid 4px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #efce7d, #7192e4);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #ececec inset;
}

.partner__nest {
  margin-bottom: 1rem;
}

.arrowImg {
  position: absolute;
  top: 73%;
  right: 12%;
  width: 12%;
}

.openModalBtn{
  background-color: #ececec;
  height: 85%;
  cursor: pointer;
}

.esl .logos{
  width: 70%;

  @media screen and (max-width: 800px) {
    width: 50%
  }
}

@media screen and (max-width: 1850px) {
  .our__partners {
    width: calc(1580px / 5);
  }

  .long__partners {
    width: calc(1580px / 5 * 2 + 1rem);
  }
}

@media screen and (max-width: 1750px) {
  .our__partners {
    width: calc(1400px / 5);
    padding: 2rem;
  }

  .long__partners {
    width: calc(1400px / 5 * 2 + 1rem);
  }
}

@media screen and (max-width: 1600px) {
  .partners {
    flex-wrap: wrap;
  }

  .partners__pointer{
    margin-top: 0.5rem;
  }

  .partners__right {
    display: flex;
  }

  .partners__for_long {
    width: calc(1400px / 5 * 2 + 1rem);
    height: 313px;
  }
}

@media screen and (max-width: 1440px) {
  h3 {
    font-size: 2rem;
  }

  .arrowImg {
    width: 3%;
    right: 8%;
    top: 63%;
  }
}

@media screen and (max-width: 1024px) {
  .our__partners {
    .partners__grayscale {
      filter: grayscale(0%);
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      -ms-filter: grayscale(0%);
      -o-filter: grayscale(0%);
    }
  }

  .partners__right {
    flex-direction: column;
  }

  .arrowImg {
    width: 6%;
    top: 82%;
    right: 20%;
  }
}

@media screen and (max-width: 820px) {
  .arrowImg {
    width: 5%;
    top: 83%;
    right: 11%;
  }

  .esl{
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .our__partners {
    width: calc(440px / 2);
    height: 220px;
  }

  .long__partners {
    width: 456px;
  }

  .partners__high_mob {
    height: 451px;
  }

  .partners__for_long{
    width: 456px;;
  }
}

@media screen and (max-width: 428px) {
  .partners {
    margin: 0 0.5rem;
  }

  .our__partners {
    width: calc(350px / 2);
    height: 175px;
  }

  .our__partners h3 {
    font-size: 1.6rem;
  }

  .long__partners {
    width: 355px;
  }

  .partners__high_mob {
    height: 358px;
  }

  .partners__right {
    flex-direction: row;
  }

  #offer {
    font-size: 1rem;
  }

  .arrowImg {
    top: 72%;
    width: 5%;
  }
}

@media screen and (max-width: 375px) {
  .our__partners {
    width: 160px;
    height: 160px;
  }

  .long__partners {
    width: calc(320px + 0.7rem);
  }

  .partners__high_mob {
    height: calc(320px + 0.7rem);
  }
}

@media screen and (max-width: 320px) {
  .our__partners {
    width: 140px;
    height: 140px;
    border-radius: 25px;
  }

  .long__partners {
    width: calc(275px + 0.7rem);
  }

  .partners__high_mob {
    height: calc(275px + 0.7rem);
  }
}