$textColor: #000000;

$mainColor: #FFFCF5;
$secondaryColor: #FFE484;

$lightGreen: #d3ffc4;

$pink: #fdabaf;
$pink-light: #ffe0e6;

$dark: #252525;
$light: #eee;
$primary: #1b6eb8;
$blue: #293b49;

$background: #f1f1f1;
$border: #6a6a6a;

$td: 0.3s; // transition duration

// font size

$mainText: (
  "mobile": 16px,
  "tablet": 20px,
  "small": 22px,
  "wide": 24px,
  "large": 26px,
);

$smallText: (
  "mobile": 18px,
  "tablet": 18px,
  "small": 20px,
  "wide": 22px,
  "large": 22px,
);

// quiz card

$cardBig: 400px; // should be devidiable by 10 cause MenuCard has flex-grow radio 2 / 8 = 10
$cardNormal: 350px;
$cardSmall: 300px;

$cardSizes: (
  3: $cardBig, // 3 in a row
  4: $cardNormal, // 4 in a row
  5: $cardSmall, // 5 in a row
);

$quizLayoutGridGap: 30px;
