.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, .85);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  top: 0;
  left: 0;
}

.modalContainer {
  width: 775px;
  height: 550px;
  border-radius: 50px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  
  @media screen and (max-width: 800px) {
    width: 650px;
    height: 475px;
  }

  @media screen and (max-width: 450px) {
    width: 345px;
    height:650px;
    padding: 15px;
  }

  @media screen and (max-width: 320px) {
    width: 100vw;
    height: 90vh;
    border-radius: 0;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
    
    button {
      background-color: transparent;
      border: none;
      font-size: 25px;
      cursor: pointer;
    }
  } 

  .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  
    h3{
      margin-bottom: 1rem;
    }
  
    p{
      font-size: 1.3rem;
    }
  }

  .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;

    input {
      border: 1px solid;
      border-radius: 50px;
      height: 3rem;
      width: 90%;
      padding-left: 1rem;
      margin: 1rem 0 1rem 0;
    }
  }

  .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 200px;
      height: 4rem;
      margin: 10px;
      box-sizing: border-box;
      display: inline-block;
      padding: 0.5em 0;
      background: none;
      border: none;
      border-top: 2px solid #efce7d;
      border-bottom: 2px solid #efce7d;
      border-image: linear-gradient(to right, #efce7d 0%, #7192e4 100%);
      border-image-slice: 1;
      position: relative;
      transition: background 0.3s;
      color: black;
      cursor: pointer;
      font-size: 1em;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 2em;
        height: calc(100% + 4px);
        border-radius: 3em 0 0 3em;
        border: 2px solid #efce7d;
        position: absolute;
        border-right: none;
        transition: background 0.3s;
        left: -2em;
        top: -2px;
      }
      &:after {
        border-radius: 0 3em 3em 0;
        border: 2px solid #7192e4;
        position: absolute;
        border-left: none;
        left: auto;
        right: -2em;
        top: -2px;
      }
      &:hover {
        background: rgba(36, 36, 36, 0.1);
        &:after,
        &:before {
          background: rgba(36, 36, 36, 0.1);
        }
      }
    }
  }
}

@media screen and (max-width: 428px) {
  .modalContainer{

    .titleCloseBtn {
      
      button {
        margin-right: 1rem;
      }
    } 

    .body{
      flex-direction: column;
      margin-top: 1rem;
    }

    .title{

      p{
        font-size: 1rem;
      }
    }

    .footer{
      margin: 0;
    }
  }
}