.our_projects {
  margin-top: 3rem;
}

.title__block {
  padding-left: 5%;
  font-size: 2.8rem;
  font-weight: 400;
}

.projects_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F0ECF1;
  border-radius: 100px;
  width: 100%;
  height: 826px;
  margin: 3% 0 0 0;
  padding: 0 0 0 5%;
  overflow: hidden;
}

.projects_text {
  width: 35%;

  .takeStud_content {
    margin-top: 7%;

    p {
      margin-bottom: 5%;
      font-size: 1.4rem;
      line-height: 1.3;
    }
  }
}

.projects_text h2 {
  font-size: 4.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.projects_text p {
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.3;
}

.projects__text_card {
  line-height: 1.3;
}

.projects__text_card li {
  font-size: 1.4rem;
}

.nmt__text {
  margin: 0 0 20% 0;
}

.knopka__opacity {
  width: 60%;
  min-width: fit-content;
  height: 3.4rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #1d1e1f;
  cursor: pointer;
  border-image-source: initial;
  border: solid 1px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #efce7d, #7192e4);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #F0ECF1 inset;

  a {
    font-size: 1.2rem;
  }
}

.projects_cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 65%;
  padding: 2%;
  overflow: hidden;

  .stud__cards {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    position: relative;
    animation: spin 15s infinite linear;

    .stud__cards_pos {
      position: absolute;
    }

    .cards {
      width: 45%;
      display: flex;
      justify-content: center;
    }


    #card_gr {
      transform: rotate(-8deg);
      top: -9%;
      left: 29%;
      width: 45%;
    }

    #card_or {
      transform: rotate(-25deg);
      top: 27%;
      left: 7%;
      width: 45%;
      z-index: 2;
    }

    #card_blue {
      transform: rotate(10deg);
      top: 52%;
      left: 31%;
      width: 45%;
      z-index: 1;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }

  #card_teach {
    width: 38%;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    top: 17%;
    position: absolute;
  }
}

.projects__cards_stud {
  padding: 2% 0 2% 2%;
}

.cartiz {
  max-width: 88%;
}

#nmt {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#nmt__screen {
  max-width: 100%;
  border-radius: 50px;
}

.nmt__icons {
  max-width: 100%;
}

.projects_icons {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.nmt__icons {
  max-width: 100%;
  position: relative;
}

#ukr_lang {
  top: 60%;
  animation-name: cardLangMove;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -1.5s;
  animation-direction: alternate;
  transform-origin: 50%;
}

@keyframes cardLangMove {
  0% {
    transform: rotate(-27deg);
  }

  100% {
    transform: rotate(27deg);
  }
}

#ukr_history {
  top: 30%;
  animation-name: cardHistMove;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -1.5s;
  animation-direction: alternate;
  transform-origin: 50%;
}

@keyframes cardHistMove {
  0% {
    transform: rotate(27deg);
  }

  25% {
    transform: rotate(13.5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-13.5deg);
  }

  100% {
    transform: rotate(-27deg);
  }
}

#mathematics {
  top: 80%;
  animation-name: cardMathMove;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -1.5s;
  animation-direction: alternate;
  transform-origin: 50%;
}

@keyframes cardMathMove {
  0% {
    transform: rotate(27deg);
  }

  100% {
    transform: rotate(-27deg);
  }
}

.ebook__text {
  margin: 0 0 20% 0;
}

.ebook__img_block {
  height: 100%;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.iBook {
  animation: float 1s infinite;
  cursor: pointer;
  transition: all 0.3s ease;

  &:after {
    width: 20px;
    height: 20px;
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -15px;
    border-radius: 2px;
    z-index: -1;
    background-color: black;
    transform: rotate(45deg);
  }

  @keyframes float {
    0% {
      transform: translate3d(0, 0, 0);
    }

    50% {
      transform: translate3d(0, 10px, 0);
    }
  }
}

.vpo__text {
  margin: 0 0 20% 0;
}

.vpo__img_block {
  width: 62%;
  height: 100%;
  margin: 0 4% 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .vpo__img {
    max-width: 100%;
  }

  .dp_vpo_img {
    position: absolute;
    right: 28%;
    width: 18%;
  }
}

.blob {
  background: rgba(52, 172, 224, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  margin: 10px;
  height: 1.5rem;
  width: 1.5rem;
  transform: scale(1);
  animation: pulse-blue 2s infinite;
  position: absolute;
  right: 33%;
}

.blob.blue {
  background: rgba(52, 172, 224, 1);
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

@media screen and (max-width: 1440px) {
  .our_projects {
    margin-top: 3rem;
  }

  .projects__text_card li {
    font-size: 1.2rem;
  }

  .projects_text h2 {
    font-size: 3.8rem;
  }

  .projects_text p {
    font-size: 1.2rem;
  }

  .projects_content {
    height: 680px;
    border-radius: 60px;
  }

  .takeStud_content {
    margin-top: 10%;

    p {
      margin-bottom: 3rem;
    }
  }

  #card_gr {
    width: 78%;
    left: 43%;
  }

  #card_or {
    width: 78%;
    left: 16%;
  }

  #card_bl {
    width: 78%;
  }

  .cartiz {
    max-width: 94%;
  }

  .nmt__icons {
    max-width: 25%;
  }

  #ukr_lang {
    top: 72%;
  }

  #ukr_history {
    top: 42%;
  }

  #mathematics {
    top: 86%;
  }

  .iBook {
    width: 98%;
  }

  .blob {
    height: 1.35rem;
    width: 1.35rem;
  }
}

@media screen and (max-width: 1024px) {
  .projects_content {
    border-radius: 50px;
  }

  .title__block {
    margin-bottom: 2%;
  }

  #stud_text {
    width: 40%;
  }

  .knopka__opacity {
    width: 85%;
  }

  .projects_text {
    h2 {
      font-size: 3rem;
    }

    p {
      font-size: 1.25rem;
    }

    .takeStud_content p {
      font-size: 1.3rem;
    }
  }

  .projects__cards_stud {
    width: 60%;
  }

  .projects_cards {

    .stud__cards {

      .cards {

        .cartiz {
          max-width: 115%;
        }
      }

      #card_gr {
        width: 49%;
        top: -4%;
        left: 23%;
      }

      #card_or {
        width: 49%;
        left: 3%;
        top: 29%;
      }

      #card_blue {
        width: 49%;
        left: 30%;
        top: 53%;
      }
    }

    #card_teach {
      width: 38%;
      right: -1%;
      top: 22%;
    }

    .projects_icons {
      margin-top: 3rem;
    }

    #nmt {
      justify-content: space-evenly;
    }

    #nmt__screen {
      margin-bottom: 3rem;
    }
  }

  .projects_content_nmt {
    height: 563px;

    .projects_text {
      width: 35%;
    }

    .projects_cards {
      width: 65%;
    }
  }

  .ebook {
    height: 563px;

    .projects_text {
      width: 38%;
    }

    .ebook__img_block {
      overflow: hidden;

      .iBook {
        width: 120%;
      }
    }
  }

  .vpo {
    height: 563px;
  }

  .blob {
    height: 1.15rem;
    width: 1.15rem;
  }
}

@media screen and (max-width: 820px) {
  .our_projects h3 {
    font-size: 2.2rem;
  }

  .projects_content {
    flex-direction: column;
    padding: 5%;
    height: 726px;
    border-radius: 45px;
  }

  .projects_text {
    width: 100%;
  }

  .projects_content h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1.3rem;
  }

  .divButton {
    display: flex;
    justify-content: center;
  }

  .button {
    width: 70% !important;
  }

  .stud__content {
    height: 986px;
    margin: 7% 0 0 0;

    .projects__cards_stud {
      width: 100%;

      .stud__cards {

        #card_gr {
          width: 40%;
          top: -13%;
          left: 32%;
        }

        #card_or {
          width: 40%;
          top: 20%;
          left: 0%;
        }

        #card_blue {
          width: 40%;
          top: 47%;
          left: 33%;
        }
      }

      #card_teach {
        width: 32%;
        top: 10%;
      }
    }
  }

  #stud_text {
    width: 100%;
    margin: 2rem 0;
  }

  #stud_text li {
    font-size: 1.2rem;
  }

  .knopka__opacity {
    width: 60%;
  }

  .projects_content_nmt {
    .projects_text {
      width: 100%;
      margin-top: 1rem;
    }

    .nmt__text {
      margin: 0 0 5% 0;
    }

    .projects_cards {
      width: 100%;
      height: 77%;
      padding: 0;
      overflow: hidden;
    }

    #nmt {
      width: 100%;
      height: 85%;
      padding: 0%;
    }

    .nmt__icons {
      width: 20%;
    }

    #ukr_lang {
      top: 84%;
    }

    #ukr_history {
      top: 60%;
    }

    #mathematics {
      top: 95%;
    }

    #nmt__screen {
      border-radius: 50px;
    }
  }

  .ebook {
    padding: 5% 5% 0 5%;

    .projects_text {
      width: 100%;

      .ebook__text {
        margin: 0 0 5% 0;
      }
    }

    .ebook__img_block {
      width: 73%;
      position: relative;

      .iBook {
        position: absolute;
        top: 6%;
      }
    }
  }

  .vpo__text {
    margin: 0 0 5% 0;
  }

  .vpo__img_block {
    width: 100%;
    margin: 0;
    padding: 5%;
  }

  .knopka {
    height: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .our_projects {
    margin-top: 3rem;
  }

  .projects_content {
    border-radius: 0;
  }

  br {
    display: none;
  }

  .knopka__opacity {
    width: 65%;
  }
}

@media screen and (max-width: 428px) {

  .projects_content {
    width: 100%;
    border-radius: 0;
    height: 450px;
    margin: 7% 0;

    .projects_text {

      .takeStud_content {

        p {
          font-size: 1.1rem;
        }
      }
    }
  }

  .projects_text h2 {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }

  .projects_text p {
    font-size: 1rem;
  }

  .stud__content {
    height: 755px;
  }

  .knopka__opacity {
    width: 55%;
    height: 2.5rem;

    a {
      font-size: 0.9rem;
    }
  }

  #stud_text li {
    font-size: 1rem;
  }

  .projects_content_nmt {
    height: 563px;
  }

  .nmt__content {
    padding: 5%;
    height: 420px;
  }

  .projects_icons {
    margin-top: 0;
  }

  .vpo {
    height: 510px;
    padding: 5% 5% 0 5%;
  }

  .blob {
    height: 0.6rem;
    width: 0.6rem;
  }
}

@media screen and (max-width: 320px) {
  .knopka__opacity {
    width: 65% !important;
  }

  .ebook {
    height: 390px;
  }
}