.register {
  background-image: url(../../../../../assets/images/preview/bg-header.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 810px;
  padding: 0 0 0 5%;
  /* margin: 120px 0 0 0; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #1d1e1f;
  line-height: 1.7;
}

.register p {
  font-size: 1.6rem;
}

.register__content {
  display: flex;
  flex-direction: column;
  width: 34%;

  .divButton{

    @media screen and (max-width: 500px) {
      padding-right: 5%;
    }
  }
}

.register__text {
  margin: 0 0 20% 0;
}

.register__text h2 {
  font-size: 3.9rem;
  font-weight: bold;
}

.button {
  width: 75% !important;
  height: 3.4rem !important;
  font-size: 1.5rem;
  background-color: transparent;
  text-decoration: none;
  border-radius: 50px;
  border: 1px solid #7192e4;
  color: #1d1e1f;
  cursor: pointer;
}

.button__link {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.button:hover{
  text-decoration: none;
}

/*.btn_digitalization{
    transform: rotate(3.91deg);
    height: 3.4rem;
    padding: 0.5rem;
    font-size: 1.5rem;
    background-color: #fff;
    border-radius: 50px;
    border: 3px solid;
    border-image: linear-gradient(139deg, #EFCE7D, #7192E4) 1;
}*/

@media screen and (max-width: 1440px) {
  .register {
    height: 685px;
    /* margin: 100px 0 0 0; */
    background-size: cover;
    border-radius: 100px;

    p {
      font-size: 1.4rem;
    }
    h2 {
      font-size: 3.1rem;
    }
  }

  .register p {
    font-size: 1.4rem;
  }

  .projects_text h2 {
    font-size: 3.1rem;
  }

  .projects_text p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1024px) {
  .register {
    height: 628px;
    background-size: cover;
    border-radius: 100px;
  }

  .register__content {
    width: 46%;
  }
}

@media screen and (max-width: 820px) {
  .register {
    height: 550px;
    background-size: cover;
    border-radius: 50px;
    margin: 25px 0;

    .register__content {
      width: 51%;

      h2 {
        font-size: 2.5rem;
      }
    }
  }

  .register p {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 500px){
  .register{
    border-radius: 0;
    background-size: 160%;
    background-position: top;
    height: 77vh;
    margin-bottom: 0;

    .register__content {
      width: 100%;
      margin-top: 22rem;

      h2{
        font-size: 2.15rem;
      }
    }

    .register__text {
      margin: 0 0 7% 0;
    }
  }
}

@media screen and (max-width:450px) {
  .register{

    .register__text h2{
      font-size: 2.05rem;
    }
  }
}

@media screen and (max-width: 428px) {
  .register {
    margin-top: 0.7rem;
    background-size: 170%;
    background-position: top;
    height: 70vh;
    margin-bottom: 2rem;
    padding-top: 3rem;

    .register__content {
      margin-top: 21rem;
    }

    .register__text {
      margin: 0 0 10% 0;
    }

    .register__text h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }
}

@media screen and (max-width: 390px) {
  .register{

    .register__text h2{
      font-size: 1.9rem;
    }
  }
}

@media screen and (max-width: 375px) {
  .register {
    background-size: 140%;
    height: 80vh;

    .register__content {
      margin-top: 33vh;
    }

    .register__text h2 {
      font-size: 1.75rem;
    }
  }
}

@media screen and (max-width: 320px) {
  .register{
    height: 52vh;

    .register__content{
      margin-top: 13rem;
    }

    .register__text{
      h2{
        font-size: 1.6rem;
      }
    }

    p{
      font-size: 1.05rem;
    }
  }
}
