.memorandum {
    flex-direction: column;
    padding: 0;
    height: 725px;

    @media screen and (max-width: 1100px) {
        height: auto;
    }

    .memorandum__prew {
        background-image: url(../../../../../assets/images/memorandums/mem_background.png);
        background-repeat: no-repeat;
        background-position: top;
        height: 300px;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media screen and (max-width: 1100px) {
            background-size: cover;
            margin-bottom: 2rem;
        }

        @media screen and (max-width: 450px) {
            height: 120px;
            margin-bottom: 2rem;
        }

        h2 {
            font-size: 4rem;
            font-weight: bold;
            margin-bottom: 3rem;

            @media screen and (max-width: 1440px) {
                font-size: 3.5rem;
            }

            @media screen and (max-width: 1100px) {
                font-size: 2.5rem;
            }

            @media screen and (max-width: 800px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 450px) {
                font-size: 1.4rem;
                background-color: rgb(236, 236, 236, 0.5);
                margin-bottom: 0;
                padding: 0 1rem 0 1rem;
            }
        }
    }

    .memorandums__uni {
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;

        .memorandums__part {
            width: 31%;
            height: 170px;
            border-radius: 100px;
            margin-bottom: 1rem;
            background-color: #FFFFFF;
            align-content: center;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 1100px) {
                width: 47%;
                margin-bottom: 2rem;
            }

            @media screen and (max-width: 800px) {
                flex-direction: column;
                height: 230px;
                border-radius: 75px;
                justify-content: center;
            }

            @media screen and (max-width: 450px) {
                border-radius: 50px;
                height: 200px;
                width: 80%;
                padding: 2rem;
                margin-bottom: 1rem;
            }

            .memorandums__part_img {
                display: flex;
                align-items: center;
                justify-content: center;

                .memorandums__img {
                    width: 75%;
                    object-fit: scale-down;

                    @media screen and (max-width: 1100px) {
                        width: 70%;
                    }

                    @media screen and (max-width: 450px) {
                        width: auto !important;
                        object-fit: none;
                    }
                }

                .memorandums__img:nth-child(2) {

                    @media screen and (max-width: 450px) {
                        width: 50%;
                    }
                }
            }

            .memorandums__text {
                text-wrap: wrap;

                @media screen and (max-widht: 450px) {
                    height: 30%;
                }

                ;

                p {
                    font-size: 1.5rem;
                    line-height: 1.7rem;

                    @media screen and (max-width: 1440px) {
                        font-size: 1.2rem;
                        // padding-left: 1.25rem;
                    }

                    @media screen and (max-width: 1100px) {
                        font-size: 1.1rem;
                        // padding-left: 1.15rem;
                    }

                    @media screen and (max-width: 800px) {
                        font-size: 1rem;
                        text-align: center;
                    }

                    @media screen and (max-width: 500px) {
                        padding: 0;
                        font-size: 0.85rem;
                        line-height: 1.5rem;
                    }
                }
            }
        }

        .memorandums__part:nth-child(2) {

            @media screen and (max-width: 800px) {
                padding: 0;

                .memorandums__part_img {
                    width: 50%;
                }
            }
        }
    }

    .memorandum__button_block {
        width: 31%;
        margin-bottom: 1rem;

        @media screen and (max-width: 1100px) {
            margin: 0 0 2rem 0;
            width: 45%;
        }

        @media screen and (max-width: 800px) {
            width: 47%;
        }

        @media screen and (max-width: 450px) {
            width: 80%;
        }

        .memorandum__button {
            width: 100%;
            height: 170px;
            border-radius: 100px;
            background-color: #FFFFFF;
            font-size: 2.5rem;
            position: relative;

            @media screen and (max-width: 800px) {
                height: 230px;
                border-radius: 75px;
            }

            @media screen and (max-width: 450px) {
                border-radius: 50px;
                font-size: 1.5rem;
                height: 175px;
            }

            .memorandum__arrow {
                position: absolute;
                right: 15%;
                bottom: 15%;
            }
        }
    }
}