$green: #82E471;
$blue: #7192E4;
$orange: #F06F46;

.targets {
  margin: 4rem 0;
  position: relative;
}

.title__block {
  width: fit-content;
  margin-left: 6rem;
  font-size: 3rem;
}

.targets__content {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  overflow: hidden;

  .target__arrow {
    width: 30%;

    img {
      width: 100%;
      transform: rotate(160deg);
    }
  }

  .target__texts {
    width: 45%;
    // margin: 4rem 0 0 54rem;

    .target__text {
      display: flex;
      cursor: default;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 260px;
      margin-bottom: 4rem;
      padding: 9%;
      border-radius: 50px;
      box-shadow: 16px 14px 17px -2px #dedede;
      transition: box-shadow 0.3s;


      h2 {
        font-size: 3.9rem;
        font-weight: 700;
      }

      p {
        font-size: 2.2rem;
        font-weight: 400;
        width: 100%;

        .target__num {
          color: $blue;
        }

        .num__green {
          color: $green;
        }

        .num__orange {
          color: $orange;
        }
      }
    }

    .shadow_blue:hover {
      box-shadow: 16px 13px 17px -4px $blue;
    }

    .shadow_green:hover {
      box-shadow: 16px 13px 17px -4px $green;
    }

    .shadow_orange:hover {
      box-shadow: 16px 13px 17px -4px $orange;
    }
  }
}

@media screen and (max-width: 1440px) {
  .targets__content {

    .target__arrow {
      left: -2%;
    }

    .target__texts {
      // margin: 4rem 0 0 35rem;
      width: 53%;
    }
  }

  .target__text {

    h2 {
      font-size: 3.1rem;
    }

    #target_text_p {
      width: 85%;
    }

    p {
      font-size: 1.8rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .targets__content {

    .target__arrow {
      left: 1%;
    }

    .target__texts {
      // margin: 4rem 0 0 19rem;

      .target__text {

        p {
          font-size: 1.75rem;
        }
      }

      .shadow_blue {
        box-shadow: 16px 13px 17px -4px $blue;
      }

      .shadow_green {
        box-shadow: 16px 13px 17px -4px $green;
      }

      .shadow_orange {
        box-shadow: 16px 13px 17px -4px $orange;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .targets {
    margin: 2rem 0;
  }

  .title__block {
    font-size: 2.2rem;
    margin-left: 1.4rem;
  }

  .target__arrow {
    align-self: flex-start;
    rotate: (45deg);
  }

  .targets__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .target__arrow {
      width: 42%;
    }

    .target__texts {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // margin: 7rem 0 0 1rem;
      width: 90%;

      .target__text {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 6%;

        p {
          font-size: 1.5rem;
          width: 91%;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {

  .targets {
    margin: 1rem;
  }

  .title__block {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .target__text {
    width: 60%;
    padding-left: 3%;
  }

  .target__num {
    font-size: 1.5rem;
  }

  #target_text_p {
    width: 100%;
  }

  .target__text p {
    font-size: 1rem;
  }

  .target__arrow img {
    width: 55%;
  }
}

@media screen and (max-width: 375px) {

  .target__num {
    font-size: 1.25rem;
  }

  .targets__content {

    .target__arrow {
      width: 42%;
    }

    .target__texts {

      .target__text {
        margin-bottom: 2rem;
        padding: 0 11%;
        height: 200px;

        p {
          font-size: 1.25rem;
        }
      }
    }
  }
}